@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Black.ttf') format('truetype');
  font-weight: 900; /* Tloušťka Black */
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-BlackItalic.ttf') format('truetype');
  font-weight: 900; /* Tloušťka Black */
  font-style: italic; /* Italic */
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700; /* Tloušťka Bold */
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-BoldItalic.ttf') format('truetype');
  font-weight: 700; /* Tloušťka Bold */
  font-style: italic; /* Italic */
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 800; /* Tloušťka Extra-Bold */
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800; /* Tloušťka Extra-Bold */
  font-style: italic; /* Italic */
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-ExtraLight.ttf') format('truetype');
  font-weight: 200; /* Tloušťka Extra-Light */
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200; /* Tloušťka Extra-Light */
  font-style: italic; /* Italic */
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Italic.ttf') format('truetype');
  font-style: italic; /* Italic */
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Light.ttf') format('truetype');
  font-weight: 300; /* Tloušťka Light */
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-LightItalic.ttf') format('truetype');
  font-weight: 300; /* Tloušťka Light */
  font-style: italic; /* Italic */
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500; /* Tloušťka Medium */
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-MediumItalic.ttf') format('truetype');
  font-weight: 500; /* Tloušťka Medium */
  font-style: italic; /* Italic */
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400; /* Tloušťka Regular */
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600; /* Tloušťka Semi-Bold */
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600; /* Tloušťka Semi-Bold */
  font-style: italic; /* Italic */
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Thin.ttf') format('truetype');
  font-weight: 100; /* Tloušťka Thin */
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-ThinItalic.ttf') format('truetype');
  font-weight: 100; /* Tloušťka Thin */
  font-style: italic; /* Italic */
}
body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  background-color: "#ffffff" !important;
}

/* .MuiTypography-h4 {
  font-size: 30px !important;
}

.MuiTypography-h3 {
  font-size: 44px !important;
} */